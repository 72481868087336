import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import AvatarCubit from "src/state/AvatarCubit/AvatarCubit";
import { useBloc } from "src/state/state";
import type {
  ChatMessage,
  KnownSenderType
} from "src/ui/components/Chat/ChatBloc";
import ChatMessageContent from "src/ui/components/Chat/ChatMessageContent";
import LabValueItem from "src/ui/components/LabValueItem/LabValueItem";
import DisplayFileAttachmentsFileList from "src/ui/components/Chat/DisplayFileAttachmentsFileList";
import UserFeedback from "../Feedback/UserFeedback";
import type { Rating } from "../Feedback/Feedback";
import ChatMessageContext from "./ChatMessageContext";
import {
  METADATA_NPS_FEEDBACK_RATING,
  METADATA_NPS_FEEDBACK_RATING_NUMBER,
  METADATA_NPS_FEEDBACK_RATING_TEXT
} from "src/constants/misc";
import { MessageComponent } from "src/ui/components/Chat/components/Message";

const ChatMessageItemFrame = styled.div`
  width: 100%;
`;

const ChatMessageRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: min(100%, 640px);
  padding: 0 1em 1em;

  ul > li {
    margin: 0.2em 0;
  }
`;

const ChatMessageItem: FC<{
  message: ChatMessage;
  isLastMessage: boolean;
  markMessageAsRead?: (message: ChatMessage) => void;
}> = ({ message, isLastMessage, markMessageAsRead }) => {
  const [avatar] = useBloc(AvatarCubit, {
    create: () => new AvatarCubit(message.apiItem?.senderId)
  });
  const navigate = useNavigate();

  const { attachedLifelineItems, messageSentDate, isLocal, isNpsFeedback } =
    message.computed;

  const rating: Rating =
    isNpsFeedback && message.apiItem?.metadata?.[METADATA_NPS_FEEDBACK_RATING];

  React.useEffect(() => {
    if (isLastMessage && markMessageAsRead && !isLocal) {
      markMessageAsRead(message);
    }
  }, [isLastMessage, markMessageAsRead, message, isLocal]);

  const avatarFallback =
    message.senderType === "STAFF_PHYSICIAN" ? "/9amAvatar.png" : undefined;
  const avatarUrl = avatar.path ?? avatarFallback;

  const handleOpenDetails = (): void => {
    const bioPopupRoles: KnownSenderType[] = [
      "STAFF_CUSTOMER_SUPPORT",
      "STAFF_DIABETES_EDUCATOR",
      "STAFF_PHYSICIAN",
      "STAFF"
    ];
    const show = bioPopupRoles.includes(
      message.apiItem?.senderMetadata?.role ?? ""
    );
    if (show) navigate(`?popup=teamDetails&id=${message.apiItem?.senderId}`);
  };

  return (
    <ChatMessageRow data-local={isLocal}>
      <ChatMessageItemFrame>
        <ChatMessageContext.Provider value={{ id: message.id }}>
          <MessageComponent
            message={message}
            openDetails={handleOpenDetails}
            profileImage={avatarUrl}
          >
            <ChatMessageContent message={message} />

            <DisplayFileAttachmentsFileList message={message} />

            {isNpsFeedback && (
              <UserFeedback
                rating={rating}
                ratingGrade={
                  message.apiItem?.metadata?.[
                    METADATA_NPS_FEEDBACK_RATING_NUMBER
                  ]
                }
                userFeedback={
                  message.apiItem?.metadata?.[METADATA_NPS_FEEDBACK_RATING_TEXT]
                }
              />
            )}
            {messageSentDate &&
              attachedLifelineItems.map((id) => (
                <LabValueItem id={id} key={id} />
              ))}
          </MessageComponent>
        </ChatMessageContext.Provider>
      </ChatMessageItemFrame>
    </ChatMessageRow>
  );
};

export default ChatMessageItem;
