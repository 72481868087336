import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { IconPaperclipAttachmentFile } from "src/constants/icons";
import fileSizeString from "src/lib/fileSizeString";
import translate from "src/lib/translate";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import type { MessageFileMetadata } from "src/ui/components/Chat/ChatBloc";

const Attachment = styled.button`
  label: Attachment;
  outline-color: transparent;
  cursor: pointer;
  text-align: left;
  display: flex;
  margin: 0.5em 0 0;
  background: var(--color-white);
  position: relative;
  align-items: stretch;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-light);
  max-width: 100%;
  z-index: 1;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .async-content {
    position: absolute;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    height: auto;
    transition: opacity 0.3s ease-out;
  }

  &[data-loading="true"] {
    .async-content {
      opacity: 0.6;
      pointer-events: auto;
    }
  }

  &:first-of-type:not(:last-of-type) {
    margin-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    --wbr-lb: 0;
  }

  &:last-of-type:not(:first-of-type) {
    margin-top: 0;
    border-top: 0;
    border-radius: 0 0 0.5rem 0.5rem;
    --wbr-lt: 0;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
    border-top: 0;
    margin: 0;
    --wbr-lt: 0;
    --wbr-lb: 0;
  }
`;

const Icon = styled.div`
  background: var(--gradient-sunrise);
  color: var(--color-charcoal-60);
  display: grid;
  place-items: center;
  padding: 0.8em 0.5em;
  position: relative;

  svg {
    width: 1.6em;
    height: 1.6em;
    position: relative;
    z-index: 5;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: var(--wbr-lt, 0.4rem) 0 0 var(--wbr-lb, 0.4rem);
    background: var(--color-white);
    opacity: 0.6;
  }
`;

const Content = styled.div`
  padding: 0.3em 1.2em 0.3em 0.6em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.04em;
  max-width: 16rem;
  background-color: #ffffff88;
  overflow: hidden;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1.2em;
  color: var(--color-charcoal);
  white-space: nowrap;
  padding: 0.1em 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Metadata = styled.div`
  font-size: 0.8em;
  line-height: 1.2em;
  color: var(--color-charcoal-60);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface FilePreviewProps extends Partial<MessageFileMetadata> {
  id?: string;
}

const FilePreview: FC<FilePreviewProps> = ({ id, name = "", type, size }) => {
  const [loading, setLoading] = useState(false);

  const loadFile = useCallback(() => {
    if (loading || !id) return;
    let mounted = true;
    setLoading(true);

    void FilesCubit.startFileDownload(id).finally(() => {
      if (!mounted) return;
      setLoading(false);
    });

    return () => {
      mounted = false;
    };
  }, [id, loading]);

  const sizeString = size ? fileSizeString(Number(size)) : "";
  const typeString = type
    ? translate(`file_type`, {
        context: type.replace("/", "_")
      })
    : "";

  const title = name.replaceAll("_", " ");

  return (
    <Attachment
      title={title}
      onClick={loadFile}
      data-loading={loading}
      className="file-attachment"
    >
      <AsyncContent check={[false]} />
      <Icon>
        <IconPaperclipAttachmentFile />
      </Icon>
      <Content>
        <Name>{title}</Name>
        <Metadata>
          {[typeString, sizeString].filter(Boolean).join(" | ")}
        </Metadata>
      </Content>
    </Attachment>
  );
};

export default FilePreview;
